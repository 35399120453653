import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';  

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CardActions from '@mui/material/CardActions';
import { toast,ToastContainer } from 'react-toastify';
import { Button} from 'react-bootstrap';

const styles = {
    watermark: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        padding: '100%',
        color: 'black',
        whiteSpace: 'nowrap'
    }
}

export const Home = ({userProps}) => {
    const [error, setError] = useState(null);    
    const localData = useMemo(() => [
            { image: 'adi.PNG', src: 'https://raw.githubusercontent.com/amasoodxellagain/km-copilot/master/km-copilot-webapp/images/adi.PNG', id: '1', name:'Adi', title: 'Adi | Agent Discovery Index', allowedrole: 'kmicro-fam-adi',owner: 'am@xellagain.com' },
            { image: 'test1.png', src: 'https://raw.githubusercontent.com/amasoodxellagain/km-copilot/master/km-copilot-webapp/images/test1.png', id: '2', name:'Lars', title: 'Lars | Learning and Resource Solutions', allowedrole: '',owner: 'am@xellagain.com' },
            { image: 'adi.PNG',src: 'https://raw.githubusercontent.com/amasoodxellagain/km-copilot/master/km-copilot-webapp/images/adi.PNG', id: '3', name:'Adi2',title: 'Adi2 | Agent Discovery Index', allowedrole: '',owner: 'am@xellagain.com' },
            { image: 'test1.png', src: 'https://raw.githubusercontent.com/amasoodxellagain/km-copilot/master/km-copilot-webapp/images/test1.png', id: '4', name:'Lars2', title: 'Lars2 | Learning and Resource Solutions', allowedrole: '',owner: 'am@xellagain.com' }
    ],[]);

    const [cards, setCards] = useState([]);

    const navigate = useNavigate();
    // Function to handle editing a card
    const handleEditCard = (cardId) => {
        console.log (cardId);
        navigate(`/agentedit/${cardId}`);
    };

    const handleAddAgent = () => {
        navigate(`/agentedit`);
    };

    // Function to handle deleting a card
    async  function handleDeleteCard(cardId) {
        // Perform the deletion logic here, such as making an API call to delete the card from the database
        // After successful deletion, you might want to remove the card from the UI
        // Prompt the user for confirmation
        const isConfirmed = window.confirm('This will permanently delete the agent. Please confirm by clicking on OK');
        if (isConfirmed) {
            // Filter out the card with the given id from the cards array
            const updatedCards = cards.filter(card => card.id !== cardId);
            // Update the state with the new array of cards
            try {
                await axios.delete(`/api/agents/${cardId}`);
                setCards(updatedCards);
                toast.success('Agent deleted successfully');
            } catch (error) {
                
                if (process.env.NODE_ENV !== 'development') {
                    setError(error);
                    toast.error('Error deleting agent:', error);
                } else {
                    setCards(updatedCards);
                }
            }
        }
    };

    async function handleRequestAccess(agent) {
        // Open a new window or dialog box for the access request
        if (window.confirm('Please click OK to request access or Cancel to abort')) {
            try {
                const email = {
                    to: agent.owner,
                    subject: 'Access Request',
                    agent: agent.name
                };
                await axios.post(`/api/email`, email);
                toast.success('Access request sent successfully');
            } catch (error) {
                toast.error('Error sending access request:', error);
            }
        }
      };

    useEffect(() => {
        async function fetchAgents() {
            try {
                const response = await axios.get('/api/agents'); // Make API call using Axios
                setCards(response.data);
            } catch (error) {
                console.error('Error fetching agents:', error);
                if (process.env.NODE_ENV !== 'development') {
                   setError(error);
                } else {
                    setCards(localData.map((agent, index) => ({
                        ...agent,
                        restricted: (userProps.isAdmin || agent.allowedrole === '' || userProps.userRoles.includes(agent.allowedrole)) ? false : true
                      })));
                }
            }
        }

        if (userProps.isAuthenticated) {
            fetchAgents();
        }
    }, [userProps, localData]); // Empty dependency array to ensure useEffect runs only once on mount

    //If there's an error, display the error message
    if (error) {
        return (<div>Error: {error.message}</div>);
    }

    const renderCard = (card, index) => (
        <Card key={card.id} style={{ maxWidth: 300, minWidth: 300 }}>
          {card.restricted ? (
            <CardActionArea onClick={() => handleRequestAccess(card)} style={{ cursor: 'pointer' }}>
              <CardMedia
                component="img"
                height="250"
                image={card.src}
                alt={card.title}
              />
              {card.restricted && <div style={styles.watermark}>
                <Typography variant="body2">Click to request access</Typography>
              </div>}
            </CardActionArea>
          ) : (
            <CardActionArea component={Link} to={`/agent/${card.id}`}>
              <CardMedia
                component="img"
                height="250"
                image={card.src}
                alt={card.title}
              />
              {card.restricted && <div style={styles.watermark}>
                <Typography variant="body2">Click to request access</Typography>
              </div>}
            </CardActionArea>
          )}
      
          <CardContent sx={{ bgcolor: "#E8E8E8", padding: "1px" }}>
            <Typography gutterBottom variant="h5" component="div" />
            <Typography variant="body2" color="text.secondary">
              {card.title}
            </Typography>
          </CardContent>
          {userProps.isAdmin && (
            <CardActions disableSpacing sx={{ bgcolor: "#E8E8E8", padding: "0px", justifyContent: "space-between" }}>
              <IconButton aria-label="edit" onClick={() => handleEditCard(card.id)}>
                <EditIcon aria-label="edit" color="primary" />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => handleDeleteCard(card.id)}>
                <DeleteIcon aria-label="delete" color="primary" />
              </IconButton>
            </CardActions>
          )}
        </Card>
      );

    return (
        <>
            <ToastContainer />
            
            <AuthenticatedTemplate>
            {cards ?(
            <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', paddingBottom: '20px'}}>
                        {cards.some(card => card.row === 1) && (
                          <h5 style={{ width: '100%', textAlign: 'center', borderBottom: '1px dotted', paddingBottom: '10px' }}>Our AI Agents</h5>
                        )}
                        {cards.filter(card => card.row===1 || !card.row).map(renderCard)}
                    </div> 
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', paddingBottom: '20px'}}>
                        {cards.some(card => card.row === 2) && (
                          <h5 style={{ width: '100%', textAlign: 'center', borderBottom: '1px dotted', paddingBottom: '10px' }}>My AI Agents</h5>
                        )}
                        {cards.filter(card => card.row===2).map(renderCard)}
                    </div> 
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', paddingBottom: '20px'}}>
                        {cards.some(card => card.row === 3) && (
                          <h5 style={{ width: '100%', textAlign: 'center', borderBottom: '1px dotted', paddingBottom: '10px' }}>Available AI Agents</h5>
                        )}
                        {cards.filter(card => card.row===3).map(renderCard)}
                    </div> 

                </div>

                <br />
                {userProps.isAdmin && (<Button variant="outline-secondary"  onClick={() => handleAddAgent()}>
                    <strong>Add AI Agent</strong> 
                </Button>)}
                </>
            ):(<div>Loading...</div>)}    
            </AuthenticatedTemplate>
            
            <UnauthenticatedTemplate>
                Please login to see the AI agents
            </UnauthenticatedTemplate>
        </>
    );
};
