import axios from 'axios';

class ImageUtils {

    // Function to upload a file to Azure Blob Storage
    async uploadFileToBlobStorage(file) {
        
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    const containerUrl = await this.generateReadWriteSASUrl(file.name);
                    const response = await fetch(containerUrl, {
                        method: 'PUT',
                        headers: { 'x-ms-blob-type': 'BlockBlob' },
                        body: reader.result
                    });
    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
    
                    console.log("File uploaded successfully.");
                    resolve();
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });

    }

    // Function to generate a SAS token for read-only access
    async generateReadOnlySASUrl(blobName) {
        // Construct the SAS endpoint URL
        const sasEndpoint = `/api/sasreadonly/${blobName}`;
        // Get the SAS token from your SAS endpoint
        const response = await axios.get(sasEndpoint);
        const blobUrl = response.data;
        // Construct the blob URL with the SAS token
        return blobUrl;
    }

    // Function to generate a SAS token for read-write access
    async generateReadWriteSASUrl(blobName) {
        // Construct the SAS endpoint URL
        const sasEndpoint = `/api/sasreadwrite/${blobName}`;
        // Get the SAS token from your SAS endpoint
        const response = await axios.get(sasEndpoint);
        const blobUrl = response.data;
        // Construct the blob URL with the SAS token
        return blobUrl;
    }

}

export default ImageUtils;