import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Navbar, Button } from 'react-bootstrap';
import { loginRequest } from '../authConfig';
import React, { useEffect } from 'react';

export const NavigationBar = (msalConfig) => {
    //Read the Client name, Navbar and signin/signout button style and Navbar text color from the authConfig.js file
    const rebrand=msalConfig && msalConfig.msalConfig && msalConfig.msalConfig.auth;
    const navbarBackGroundColor = rebrand && rebrand.navbarBackGroundColor ? rebrand.navbarBackGroundColor : "ligthsteelblue";
    const navbarTextColor = rebrand && rebrand.navbarTextColor ? rebrand.navbarColor : "black";
    const buttonStyle = rebrand && rebrand.buttonStyle ? rebrand.buttonStyle : "outline-dark";
    const appTitle = rebrand && rebrand.appTitle ? rebrand.appTitle : "KMicro Agent Family Tree";

    useEffect(() => {
        document.title = appTitle;
      }, [appTitle]);

    const { instance } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    console.log(msalConfig.auth);
    
    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
         */
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/redirect',
            })
            .catch((error) => console.log(error));
    };

    const handleLogoutPopup = () => {
        instance
            .logoutPopup({
                mainWindowRedirectUri: '/', // redirects the top level app after logout
                account: instance.getActiveAccount(),
            })
            .catch((error) => console.log(error));
    };

     return (
        <>
            <Navbar variant='dark' className="navbarStyle" style={{backgroundColor:navbarBackGroundColor}}>
                <a className="navbar-brand" href="/" style={{ color: navbarTextColor }}>
                    {msalConfig && msalConfig.msalConfig && msalConfig.msalConfig.auth && msalConfig.msalConfig.auth.appTitle} | Powered by KMicro AI
                </a>
                <AuthenticatedTemplate>
                    <div className="collapse navbar-collapse justify-content-end">
                        <Button variant={buttonStyle} onClick={handleLogoutPopup} style={{ border: '2px solid white' }}>{activeAccount ? activeAccount.name : 'Unknown'} | Sign out</Button>
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div className="collapse navbar-collapse justify-content-end">
                        <Button variant={buttonStyle} onClick={handleLoginPopup} style={{ border: '2px solid white' }}>Sign in</Button>
                    </div>
                </UnauthenticatedTemplate>
            </Navbar>
        </>
    );

};