import React, {Component} from 'react';
import ReactQuill from "react-quill"
import { Form, Row, Col, Button, Spinner, Modal} from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css'
import ImageUtils from '../utils/ImageUtils';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';


class HtmlEditor extends Component{

    constructor(agent){
      super(agent);
      this.state = {
        isNew: agent.props.id ? false : true,
        id: agent.props.id || randomId(),
        name: agent.props.name || '',
        title: agent.props.title || '',
        description: agent.props.description || '',
        src: agent.props.src || '',
        image: agent.props.image || '',
        tokenURL:agent.props.tokenURL || '',
        feedbackURL:agent.props.feedbackURL || '',
        prompts:agent.props.prompts || [],
        owner:agent.props.owner || '',
        allowedrole:agent.props.allowedrole || '',
        uploading: false,
        rowModesModel: {},
        submitted: false,
        everyOneAgent: agent.props.everyOneAgent || false
      }
      this.setPrompts = this.setPrompts.bind(this);
      this.setRowModesModel = this.setRowModesModel.bind(this);

      this.handleNameChange = this.handleNameChange.bind(this);
      this.handleTitleChange = this.handleTitleChange.bind(this);
      this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
      this.handleFileChange = this.handleFileChange.bind(this);
      this.handleTokenURLChange = this.handleTokenURLChange.bind(this);
      this.handleFeedbackURLChange = this.handleFeedbackURLChange.bind(this);
      this.handleAllowedRole = this.handleAllowedRole.bind(this);
      this.handleOwner = this.handleOwner.bind(this);
      this.handleEveryOneAgentChange = this.handleEveryOneAgentChange.bind(this);
      
      this.modules = {
            toolbar: [
                [{ 'header': [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                //['link', 'image'],
                //['clean']
            ],
        };
   
      this.formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]
    }
    
    setPrompts(newprompts){
        this.setState({prompts: newprompts});
    }

    setRowModesModel(newrowModesModel){
        this.setState(state => ({
            rowModesModel: newrowModesModel
        }));
    }

    EditToolbar(props) {
        const { setPrompts, setRowModesModel, state } = props;
      
        const handleClick = () => {
            const id = randomId();
            setPrompts([...state.prompts, { id, description: '', prompt: '', isNew: true }]);
            setRowModesModel({ ...state.rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'description' } });
          };
      
        return (
          <GridToolbarContainer>
            <Button variant="outline-secondary" color="primary" onClick={handleClick}><strong> 
              Add record
              </strong> 
            </Button>
          </GridToolbarContainer>
        );
    }     



    handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      };
    
    handleEditClick(id) {
        this.setRowModesModel({ ...this.state.rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    handleSaveClick(id){
        this.setRowModesModel({ ...this.state.rowModesModel, [id]: { mode: GridRowModes.View } });
    };
    
    handleDeleteClick(id){
        this.setPrompts(this.state.prompts.filter((prompt) => prompt.id !== id));
    };
    
    handleCancelClick(id) {
        this.setRowModesModel({
            ...this.state.rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
          });
      
          const editedRow = this.state.prompts.find((prompt) => prompt.id === id);
          if (editedRow.isNew) {
            this.setPrompts(this.state.prompts.filter((prompt) => prompt.id !== id));
          }
    };
    
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        this.setPrompts(this.state.prompts.map((prompt) => (prompt.id === newRow.id ? updatedRow : prompt)));
        return updatedRow;
    };
    
    
    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({ rowModesModel: newRowModesModel });
    };

    handleSortModelChange = (newSortModel) => {
      const sortedPrompts = [...this.state.prompts].sort((a, b) => {
        const fieldA = a[newSortModel[0].field];
        const fieldB = b[newSortModel[0].field];
    
        if (newSortModel[0].sort === 'asc') {
          return fieldA > fieldB ? 1 : -1;
        } else {
          return fieldA < fieldB ? 1 : -1;
        }
      }).map((item, index) => {
        return { ...item, index: index + 1 };
      });
    
      this.setPrompts(sortedPrompts);

    };
    

    columns = [
        { field: 'description', headerName: 'Description', width: 180, editable: true },
        { field: 'prompt', headerName: 'Prompt', width: 180, editable: true },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
    
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={() => this.handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onClick={() =>this.handleCancelClick(id)}
                  color="inherit"
                />,
              ];
            }
    
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={() => this.handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => this.handleDeleteClick(id)}
                color="inherit"
              />,
            ];
          },
        },
      ];

    handleSave = async () => {
        this.setState({submitted: true});
      
        let isValid = true;
        const requiredFields = this.form.querySelectorAll('[required]');
        requiredFields.forEach(field => {
          if (!field.value) {
            isValid = false;
          }
        });
      
        if (!isValid) {
          toast.error('Please fill in all required fields');
          return;
        }
        this.setState({ uploading: true }); // Set uploading state to true
      
        try {
            if(this.state.file){
                const imageUtils = new ImageUtils();
                await imageUtils.uploadFileToBlobStorage(this.state.file);
                this.setState.image=this.state.file.name;
                this.setState.prompts=this.state.prompts;
                console.log("File uploaded successfully.");
            }
            this.props.onChange(this.state);
        } catch (error) {
            console.error("File upload failed:", error);
            toast.error('Error updating AI agent:', error);
        }  finally {
            this.setState({ uploading: false }); // Set uploading state to false
        }
    }

    handleTokenURLChange(event){
        this.setState({tokenURL: event.target.value});
    }

    handleFeedbackURLChange(event){
      this.setState({feedbackURL: event.target.value});
  }
    
    handleFileChange(event){
        this.setState({file: event.target.files[0]});
    }

    handleNameChange(event){
      this.setState({name: event.target.value});
    }
    
    handleTitleChange(event){
        this.setState({title: event.target.value});
    }
  
    handleDescriptionChange(value){
        this.setState({description: value});
    }

    handleOwner(event){
      this.setState({owner: event.target.value});
    }

    handleAllowedRole(event){
      this.setState({allowedrole: event.target.value});
    }

    handleEveryOneAgentChange(event){
      this.setState({
        everyOneAgent: event.target.checked, 
        allowedrole: event.target.checked ? '': this.state.allowedrole});
    }

    render(){
        return (
            <>
                <Form ref={form => this.form = form}>
                    <Form.Group as={Row} className="mb-3" controlId="name">
                        <Form.Label column sm={2}>Name<span style={{ color: 'red' }}>*</span> :</Form.Label>
                        <Col sm={10}>
                          <Box sx={{boxShadow: 0,border: 1, borderColor: 'primary.light'}}>
                              <Form.Control required type="text" defaultValue={this.state.name} onChange={this.handleNameChange} className={`form-input ${this.state.submitted ? 'submitted' : ''}`} placeholder="Name of the AI agent. AI Agent named Adi will be in the top row on home."/>
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="title">
                        <Form.Label column sm={2}>Title<span style={{ color: 'red' }}>*</span> :</Form.Label>
                        <Col sm={10}>
                          <Box sx={{boxShadow: 0,border: 1, borderColor: 'primary.light'}}>
                              <Form.Control required type="text" defaultValue={this.state.title} onChange={this.handleTitleChange} className={`form-input ${this.state.submitted ? 'submitted' : ''}`} placeholder="Title displayed on AI agent card"/>
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="everyOneAgent">
                        <Form.Label column sm={2}>Available to everyone? :</Form.Label>
                        <Col sm={1}>
                          <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
                              <Form.Check type="checkbox" defaultChecked={this.state.everyOneAgent} onChange={this.handleEveryOneAgentChange} />
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="description">
                        <Form.Label column sm={2}>Description<span style={{ color: 'red' }}>*</span> :</Form.Label>
                        <Col sm={10}>
                          <Box sx={{ boxShadow: 0, border: this.state.submitted && !this.state.description  ? 2: 1, borderColor: this.state.submitted && !this.state.description ? 'error.main' : 'primary.light' }}>
                             <ReactQuill value={this.state.description}
                                modules={this.modules}
                                formats={this.formats}
                                onChange={this.handleDescriptionChange} 
                                />
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="img">
                        <Form.Label column sm={2}>AI Agent Image<span style={{ color: 'red' }}>*</span> :</Form.Label>
                        <Col sm={10}>
                          <Box sx={{boxShadow: 0,border: 1, borderColor: 'primary.light'}}>
                            <Form.Control required={!this.state.image} type="file" onChange={this.handleFileChange} className={`form-input ${this.state.submitted && !this.state.image ? 'submitted' : ''}`}/>
                            {this.state.image ?(<b>Image file: {this.state.image}</b>):(<b >Upload AI agent image</b>)}
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="allowedrole">
                        <Form.Label column sm={2}>Allowed Role :</Form.Label>
                        <Col sm={10}>
                          <Box sx={{boxShadow: 0,border: 1, borderColor: 'primary.light'}}>
                            <Form.Control type="text" disabled={this.state.everyOneAgent} value={this.state.allowedrole} onChange={this.handleAllowedRole} className="form-input" placeholder="Allowed role for AI agents"/>
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="owner">
                        <Form.Label column sm={2}>Owner{this.state.allowedrole ? <span style={{ color: 'red' }}>*</span> : null} :</Form.Label>
                        <Col sm={10}>
                          <Box sx={{boxShadow: 0,border: 1, borderColor: 'primary.light'}}>
                            <Form.Control required={this.state.allowedrole ? true:false} type="text" defaultValue={this.state.owner} onChange={this.handleOwner} className={`form-input ${this.state.submitted && this.state.allowedrole ? 'submitted' : ''}`} placeholder="AI Agent owner email address"/>
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="tokenURL">
                        <Form.Label column sm={2}>Token URL<span style={{ color: 'red' }}>*</span> :</Form.Label>
                        <Col sm={10}>
                        <Box sx={{boxShadow: 0,border: 1, borderColor: 'primary.light'}}>
                            <Form.Control required type="text" defaultValue={this.state.tokenURL} onChange={this.handleTokenURLChange} className={`form-input ${this.state.submitted ? 'submitted' : ''}`} placeholder="Agent SSO Token URL"/>
                          </Box>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="feedbackURL">
                        <Form.Label column sm={2}>Feedback Form URL<span style={{ color: 'red' }}>*</span> :</Form.Label>
                        <Col sm={10}>
                        <Box sx={{boxShadow: 0,border: 1, borderColor: 'primary.light'}}>
                            <Form.Control required type="text" defaultValue={this.state.feedbackURL} onChange={this.handleFeedbackURLChange} className={`form-input ${this.state.submitted ? 'submitted' : ''}`} placeholder="Feedback Form URL"/>
                          </Box>
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="mb-3" controlId="prompts">
                        <Form.Label column sm={2}>Prompts :</Form.Label>
                        <Col sm={10}>
                                <Box
                                    sx={{
                                      minHeight: '200px',
                                      height: '100%',
                                      width: '100%',
                                      boxShadow: 2,
                                      border: 2,
                                      borderColor: 'primary.light',
                                      '& .MuiDataGrid-cell:hover': {
                                        color: 'primary.main',
                                      }
                                    }}
                                    >
                                    <DataGrid
                                        rows={this.state.prompts}
                                        columns={this.columns}
                                        editMode="row"
                                        rowModesModel={this.state.rowModesModel}
                                        sortingOrder={['desc', 'asc']}
                                        onRowModesModelChange={this.handleRowModesModelChange}
                                        onRowEditStop={this.handleRowEditStop}
                                        processRowUpdate={this.processRowUpdate}
                                        onSortModelChange={this.handleSortModelChange}
                                        slots={{
                                            toolbar: this.EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: { setPrompts: this.setPrompts, setRowModesModel:this.setRowModesModel, state:this.state}
                                        }}
                                       
                                    />
                                </Box>

                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Button variant="outline-secondary"  onClick={this.handleSave}><strong>Save</strong></Button>
                        </Col>
                    </Form.Group>
                </Form>
                <div>
                  {/* Your other components */}
                  {this.state.uploading && 
                      <Modal show={this.state.uploading} backdrop="static" keyboard={false}>
                          <Modal.Body>
                              <Spinner animation="border" />
                          </Modal.Body>
                      </Modal>
                  }
                </div>
            </>     
        );
    }
  }
  
  export default HtmlEditor;