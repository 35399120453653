import React, { useMemo, useEffect, useState } from 'react';
import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat';
import { Button } from 'react-bootstrap';
import axios from 'axios';

async function fetchJSON(url, options = {}) {
  const res = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      accept: 'application/json'
    }
  });

  if (!res.ok) {
    throw new Error(`Failed to fetch JSON due to ${res.status}`);
  }
  return await res.json();
}

// Utility function to extract OAuth card resource URI from activity
function getOAuthCardResourceUri(activity) {
  if (activity &&
    activity.attachments &&
    activity.attachments[0] &&
    activity.attachments[0].contentType === 'application/vnd.microsoft.card.oauth' &&
    activity.attachments[0].content.tokenExchangeResource) {
    return activity.attachments[0].content.tokenExchangeResource.uri;
  }
}

// Utility function to exchange token asynchronously
async function exchangeTokenAsync(resourceUri, clientApplication) {
  const user = clientApplication.getActiveAccount()
  
  if (user) {
    const requestObj = {
      scopes: [resourceUri]
    };
    try {
      const tokenResponse = await clientApplication.acquireTokenSilent(requestObj);
      return tokenResponse.accessToken;
    } catch (error) {
        console.log("Error exchanging token: ", error);
      return null;
    }
  } else {
    return null;
  }
}

export const AgentDisplay = ({ clientApplication }) => {
  const [agent, setAgent] = useState(
    {
      image: 'test1.png',
      src: 'https://raw.githubusercontent.com/amasoodxellagain/km-copilot/master/km-copilot-webapp/images/test1.png', id: '2', name: 'Lars', title: 'Lars | Learning and Resource Solutions', description: '<b>Organizations for years have collected, stored, and wished they could find the right digital file at the right time</b>. Sound familiar?', tokenURL: 'https://d33f030cd6eae3dab904d356a6507d.05.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cr15e_kmicroAdi/directline/token?api-version=2022-03-01-preview',
      prompts: [
        {
          description: "First prompt",
          prompt: 'Hello'
        },
        {
          description: "Second prompt",
          prompt: 'Enter your prompt here'
        }
      ],
      group: '',
      owner: 'am@xellagain.com'
    }
    );

  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
   // Use useParams to get the agent ID from the URL 
  const id = window.location.pathname.split('/').pop();

  useEffect(() => {
    async function fetchAgent() {
      try {
        const response = await axios.get(`/api/agents/${id}`); // Make API call using Axios
        setAgent(response.data);
      } catch (error) {
          console.error('Error fetching AI agents:', error);
        if (process.env.NODE_ENV !== 'development') {
          setError(error);
        }
      }
    }
    fetchAgent();

  }, [id]); // Empty dependency array to ensure useEffect runs only once on mount


  useEffect(() => {
    const fetchTokenAsync = async () => {
      const theURL = agent.tokenURL;
      const response = await fetchJSON(theURL);
      setToken(response.token);
    };
    if (agent && agent.tokenURL) {
      fetchTokenAsync();
    }
  }, [agent]);

  const directLine = useMemo(() => token ? createDirectLine({ token }) : null, [token]);

  const userId =
    clientApplication.getActiveAccount()?.accountIdentifier != null
      ? ("You-customized-prefix" + clientApplication.getActiveAccount().accountIdentifier).substr(0, 64)
      : (Math.random().toString() + Date.now().toString()).substr(0, 64);


  function postMessage(message){
    directLine.postActivity({ type: 'message', text: message }).subscribe(
      id => {
        console.log(`Message sent. ID: ${id}`);
      },
      error => {
        console.error('Error sending message:', error);
      });
  };

  const store = useMemo(() => createStore({}, ({ dispatch }) => next => action => {
    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
      dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'startConversation',
          type: 'event',
          value: { text: "hello" }
        }
      });
    }
    //TO ENABLE AUTO LOGIN CHANGE action.type to 'DIRECT_LINE/INCOMING_ACTIVITY'
    if (action.type === '_DIRECT_LINE/INCOMING_ACTIVITY') {
      const activity = action.payload.activity;
      let resourceUri;
      if (activity.from && activity.from.role === 'bot' &&
        (resourceUri = getOAuthCardResourceUri(activity))) {
        exchangeTokenAsync(resourceUri, clientApplication).then(function (token) {
        if (token) {
            directLine.postActivity({
              type: 'invoke',
              name: 'signin/tokenExchange',
              value: {
                id: activity.attachments[0].content.tokenExchangeResource.id,
                connectionName: activity.attachments[0].content.connectionName,
                token
              },
              "from": {
                id: userId,
                name: clientApplication.getActiveAccount().name,
                role: "user"
              }
            }).subscribe(
              id => {
                if (id === 'retry') {
                  // bot was not able to handle the invoke, so display the oauthCard
                  return next(action);
                }
                // else: tokenexchange successful and we do not display the oauthCard
              },
              error => {
                // an error occurred to display the oauthCard
                return next(action);
              }
            );
            return;
          }
          else
            return next(action);
        });
      }
    }

    return next(action);
  }), [clientApplication, directLine, userId]);


  const styleOptions = {
    // Customize the chat container
    rootHeight: '100%',
    rootWidth: '100%',

    // Customize the background color of the chat container
    rootBackgroundColor: 'darkblue',

    // Customize the font family
    fontFamily: 'Arial, sans-serif',

    // Customize the font size
    fontSizeSmall: '12px',
    fontSizeMedium: '14px',
    fontSizeLarge: '16px',

    // Customize the colors of the chat bubbles
    bubbleBackground: 'lightblue', // Background color of the user and bot chat bubbles
    bubbleTextColor: '#333', // Text color of the user and bot chat bubbles

    // Customize the color of the send box
    sendBoxBackgroundColor: '#fff',
    sendBoxButtonColorOnHover: '#ccc',
    transcriptBackgroundColor: '#cccc',

    sendBoxBorderBottom: 'solid 2px #E6E6E6',
    sendBoxBorderLeft: 'solid 2px #E6E6E6',
    sendBoxBorderRight: 'solid 2px #E6E6E6',
    sendBoxBorderTop: 'solid 2px #E6E6E6',
    hideUploadButton: true,
    
  };

  //If there's an error, display the error message
  if (error) {
    return (<div>Error: {error.message}</div>);
  }

  // If agent data is not yet fetched, display loading message or spinner
  if (!agent) {
    return (<div>Loading...</div>);
  }

  return (
    <div className="chat-page">
      <div className="chat-container" style={{ display: 'flex', gap: '50px', justifyContent: 'left' }}>
        <div className="chat-content" style={{ width: '500px', height: '700px', border: '2px solid #ccc' }} >
          <ReactWebChat
            directLine={directLine}
            store={store}
            userID={userId}
            styleOptions={styleOptions}
          />
          <a href={agent.feedbackURL} target="_blank" rel="noopener noreferrer">
            Click here to provide your feedback
          </a>
        </div>
        <div >
          <div style={{ justifyContent: 'center', textAlign: 'left' }}>
            <div dangerouslySetInnerHTML={{ __html: agent.description }} />
          </div>
          <br></br>

          <h6>Standard Prompts</h6>
          <div style={{ display: 'flex', justifyContent: 'space-around', border: '2px solid gray', padding: '8px', margin: '8px 0' }}>
            <Button variant="outline-secondary"
              style={{  border: '1px solid black',  margin: '2px'}}
              onClick={() => postMessage('Hello')} color="primary"
            >
              Hello
            </Button>
            <Button variant="outline-secondary"
              style={{  border: '1px solid black',  margin: '2px'}}
              onClick={() => postMessage('Start over')}
            >
              Start over
            </Button>
            <Button variant="outline-secondary"
              style={{  border: '1px solid black',  margin: '2px'}}
              onClick={() => postMessage('Talk to a person')}
            >
              Talk to a person
            </Button>
          </div>
          <br></br>
          
          <h6>Agent Specific Prompts</h6>
            <table style={{ borderCollapse: 'collapse', border: '2px solid gray', width: '100%'}}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Description</th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Prompt</th>
                </tr>
              </thead>
              <tbody>
                {agent.prompts.map((prompt, index) => (
                  <tr key={index}>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{prompt.description}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>
                    {prompt.prompt && prompt.prompt.length > 0 && 
                      <Button variant="outline-secondary"
                        style={{  border: '1px solid black',  margin: '2px'}}
                        onClick={() => postMessage(prompt.prompt)}
                      >
                          {prompt.prompt}
                      </Button>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  );
}

