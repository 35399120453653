import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import HtmlEditor from './HtmlEditor';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';  


export const AgentEdit = ({ clientApplication }) => {
    const localData = useMemo(() => [
      {
        image: 'test1.png',
        src: 'https://raw.githubusercontent.com/amasoodxellagain/km-copilot/master/km-copilot-webapp/images/test1.png', 
        id: '2', 
        name: 'Lars', 
        title: 'Lars | Learning and Resource Solutions', 
        description: '<b>Organizations for years have collected, stored, and wished they could find the right digital file at the right time</b>. Sound familiar?', 
        tokenURL: 'https://d33f030cd6eae3dab904d356a6507d.05.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cr15e_kmicroAdi/directline/token?api-version=2022-03-01-preview',
        feedbackURL: "http://www.google.com",   
        prompts: [
          {
            id: '1',
            description: "First prompt",
            prompt: 'Hello'
          },
          {
            id: '2',
            description: "Second prompt",
            prompt: 'Enter your prompt here'
          }
        ],
        allowedrole: '',
        owner: 'am@xellagain.com'
      }
    ],[]);
    
    const [agent, setAgent] = useState(null);
    const [error, setError] = useState(null); 
    
    const pathParts = window.location.pathname.split('/');
    const id = pathParts[pathParts.length - 1] === 'agentedit' ? null : pathParts.pop();

    const navigate = useNavigate();

    async function handleEditorDataChange(updatedAgent) {
      try {
          updatedAgent.image=updatedAgent.file?updatedAgent.file.name:updatedAgent.image;
          if (updatedAgent.isNew) {
            await axios.post(`/api/agents`, updatedAgent);
          } else {
            await axios.put(`/api/agents/${updatedAgent.id}`, updatedAgent);
          }
          updatedAgent.isNew=false;
          setAgent(updatedAgent);
          toast.success('Agent updated successfully');
          navigate(`/`);
      } catch (error) {
          toast.error('Error updating agent:', error);
      }
    };
  
    useEffect(() => {
      async function fetchAgent() {
        try {
          const response = await axios.get(`/api/agents/${id}`); // Make API call using Axios
          setAgent(response.data);
        } catch (error) {
          console.error('Error fetching images:', error);
          if (process.env.NODE_ENV !== 'development') {
            setError(error);
          } else {
            setAgent(localData[0]);
          }
        }
      }
      
      if (id) {
        fetchAgent();
      }
       else {
         setAgent({});//set to empty object to create a new agent have to do it here if set at the time of initialization empty agent is loaded even if api returns a value
       }
    }, [id,localData]); // Empty dependency array to ensure useEffect runs only once on mount
  
    //If there's an error, display the error message
    if (error) {
        return (<div>Error: {error.message}</div>);
    }
    return (
      <div>
        <ToastContainer />
        {agent ?(<HtmlEditor props={agent} onChange={handleEditorDataChange} />):(<div>Loading...</div>)}
      </div>
      );
};

